:root{
  --font-sizes:lg, 2x, 3x, 4x, 5x;
  --font-sizes-values:1.33333333em, 2em, 3em, 4em, 5em;
  --rotates:30, 60, 90, 180, 270;
}

.flag-icon-flip-horizontal{
  transform:scale(-1, 1);
}

.flag-icon-flip-vertical{
  transform:scale(1, -1);
}

.flag-icon-lg{
    font-size:1.33333333em;
  }

.flag-icon-2x{
    font-size:2em;
  }

.flag-icon-3x{
    font-size:3em;
  }

.flag-icon-4x{
    font-size:4em;
  }

.flag-icon-5x{
    font-size:5em;
  }

.flag-icon-rotate-30{
    transform:rotate(30deg);
  }

.flag-icon-rotate-60{
    transform:rotate(60deg);
  }

.flag-icon-rotate-90{
    transform:rotate(90deg);
  }

.flag-icon-rotate-180{
    transform:rotate(180deg);
  }

.flag-icon-rotate-270{
    transform:rotate(270deg);
  }
